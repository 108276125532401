<div class="container">
    <div class="flex-container">
        <div class="cursor">
            <h1>EDITH WERBEL</h1>
        </div>
        <h2><span class="blue">&lt;</span><span class="white">Web Developer</span><span class="blue">/&gt;</span></h2>
        <div>
            <p class="cssLineHight"><span class="green">.SiteUnderConstruction </span><span class="blue">&#123;</span>
            </p>
            <p class="tab cssLineHight"><span class="yellow">Currently</span><span class="grey">:</span> <span
                    class="orange">"On maternity leave"</span><span class="grey">;</span>
            </p>
            <p class="tab cssLineHight"><span class="yellow">Employment</span><span class="grey">:</span> <span
                    class="orange">"TD Bank"</span><span class="grey">;</span>
            </p>
            <p class="tab cssLineHight"><span class="yellow">Contact</span><span class="grey">:</span>
                <span class="orange"><a href="mailto:hi@edithwerbel.com">"hi@edithwerbel.com"</a></span><span
                    class="grey">;</span>
            </p>
            <p class="cssLineHight blue">&#125;</p>
        </div>
    </div>

</div>